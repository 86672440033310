const logotext = "Najee.";
const meta = {
  title: "Najee Sidahmed",
  description:
    "I’m Najee, a frontend developer currently looking for opportunities to expand and implement my skillset",
};

const introdata = {
  title: "Najee Sidahmed",
  animated: {
    first: "I'm a Developer",
    second: "and Designer",
    third: "Call me a Devigner™",
  },
  description: "Bringing digital dreams to life.",
  your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
  title: "a bit about me",
  aboutme:
    "Web Developer currently working at a legal tech start-up. Graduated with a degree in Maths and Computer Science in 2023. My passion lies in creating unique and eye catching experiences that live on the web, while diving deeper into the endless pool of knowledge that is development. I also like to design t-shirts.",
};
const worktimeline = [
  {
    jobtitle: "Web Developer",
    where: "Finlegal",
    date: "Present",
  },
  {
    jobtitle: "Freelance Web Developer",
    where: "Remote",
    date: "2022",
  },
  {
    jobtitle: "Content Writer",
    where: "Remote",
    date: "2021",
  },
];

const skills = [
  {
    name: "Javascript",
    value: 85,
  },
  {
    name: "JSON",
    value: 94,
  },
  {
    name: "React",
    value: 80,
  },
  {
    name: "HTML/CSS",
    value: 90,
  },
  {
    name: "NodeJS",
    value: 75,
  },
  {
    name: "Python",
    value: 60,
  },
];

const services = [
  {
    title: "Custom Website Development",
    description:
      "Design, develop and deploy tailor-made responsive websites to meet clients' specific needs, ensuring optimal performance, SEO optimization, and cross-browser compatibility. Shopify and Squarespace website customisation available.",
  },
  {
    title: "Custom Animations",
    description:
      "Add custom animations to your website to make it stand out from the crowd. I use Framer Motion and GSAP to create animations that are both performant and visually appealing.",
  },
];

const projects = [
  {
    id: 1,
    name: "Nike AI Customiser",
    description:
      "This project was a great way to combine my love for fashion and fascination with AI. Designed as a concept for Nike, built in React and Vite and using ThreeJS for the 3D modelling, the application allows users to upload a logo, and prompt AI for customised designs. The backend is built using Node.js and Express, with API calls to the OpenAI API to render designs on the shirt.",
    link: "https://justdoait.netlify.app/",
    code: "https://github.com/najeesidahmed/threejs-ai-mockups",
  },

  {
    id: 2,
    name: "iPhone 15",
    description:
      "An Apple iPhone 15 website design concept inspired by the Apple Website. This project was a chance to practise using GSAP and all of its capabilities to build smooth scroll animations, image animations and timeline animations. As well as ThreeJS for the iPhone models, using lighting to create realistic effects.",
    link: "https://apple-concept-design.netlify.app",
    code: "https://github.com/najeesidahmed/apple-website",
  },
  {
    id: 3,
    name: "Breef",
    description:
      "Breef is an AI article summariser. Built in React and Redux, using a RapidAPI API to analyse the content of urls and return a summarised extract. Url search and results history is stored in local storage allowing users to retrieve the summary with one click and no load time, once the initial summary is executed.",
    link: "https://breef.netlify.app/",
    code: "https://github.com/najeesidahmed/ai-summarizer",
  },
];

const contactConfig = {
  YOUR_EMAIL: "najeekamil@msn.com",
  YOUR_PHONE: "+447795514358",
  description:
    "Contact me for any queries related to my work or if you just want to say hi. I'll try my best to get back to you as soon as possible! ",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: process.env.REACT_APP_SERVICE_ID,
  YOUR_TEMPLATE_ID: process.env.REACT_APP_TEMPLATE_ID,
  YOUR_USER_ID: process.env.REACT_APP_PUBLIC_KEY,
};

const socialprofils = {
  github: "https://github.com/najeesidahmed",
  facebook: "https://facebook.com",
  linkedin: "https://www.linkedin.com/in/najee-sidahmed/",
  twitter: "https://twitter.com",
  instagram: "https://www.instagram.com/najee.dev/",
};
export {
  meta,
  dataabout,
  projects,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
