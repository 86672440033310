import React, { useState } from 'react';
import ProjectList from './ProjectList';
import ProjectDescription from './ProjectDescription';
import { motion } from 'framer-motion';
import { projects } from '../../content_option';

const pageVariants = {
  hidden: {
    opacity: 0,
    x: '-100vw',
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 1,
      type: 'spring',
      stiffness: 30,
    },
  },
  exit: {
    opacity: 0,
    x: '100vw',
    transition: {
      ease: 'easeInOut',
    },
  },
};


const ProjectPage = () => {
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const handleClick = id => {
    setSelectedProjectId(id);
  };

  const selectedProject = projects.find(
    project => project.id === selectedProjectId
  );

  return (
    <motion.div 
    className="proj-cont"
    // variants={pageVariants}
    // initial="hidden"
    // animate="visible"
    // exit="exit"
    >
      <ProjectList projects={projects} handleClick={handleClick} />
      {selectedProject && (
        <ProjectDescription 
        project={selectedProject} />
      )}
    </motion.div>
  );
};

export default ProjectPage;
