import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataabout,
  meta,
  worktimeline,
  skills,
  services,
} from "../../content_option";
import { motion } from 'framer-motion';



const hrVariants = {
  hidden: {
    width: '0px',
  },
  visible: {
    width: '100%',
    transition: {
      delay: 1,
      duration: 2,
      ease: 'easeInOut',
      type: 'spring',
    }
  },
}

const opacity = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
      ease: 'easeInOut',
    },
  },
};

export const About = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> About | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <motion.h1 
            className="display-6 mb-4"
            variants={opacity} initial="hidden" animate="visible"
            >About me</motion.h1>
            <motion.hr 
            className="t_border my-4 ml-0 text-left"
            variants={hrVariants} initial="hidden" animate="visible"
            />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <motion.h3 
            className="about-title"
            variants={opacity} initial="hidden" animate="visible"
            >{dataabout.title}</motion.h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <motion.p
              variants={opacity} initial="hidden" animate="visible"
              >{dataabout.aboutme}</motion.p>
            </div>
          </Col>
        </Row>
        <Row className=" sec_sp">
          <Col lg="5">
            <motion.h3 
            className="about-title pt-2"
            variants={opacity} initial="hidden" animate="visible"
            >work timeline</motion.h3>
          </Col>
          <Col lg="7">
            <motion.table 
            className="table caption-top"
            variants={opacity} initial="hidden" animate="visible"
            >
              <tbody>
                {worktimeline.map((data, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{data.jobtitle}</th>
                      <td>{data.where}</td>
                      <td>{data.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </motion.table>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <motion.h3 
            className="about-title pt-3"
            // variants={titleVariants} initial="hidden" animate="visible"
            >skills</motion.h3>
          </Col>
          <Col lg="7">
            {skills.map((data, i) => {
              return (
                <div key={i}>
                  <h3 className="progress-title">{data.name}</h3>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${data.value}%`,
                      }}
                    >
                      
                      {/* <div className="progress-value">{data.value}</div> */}
                    </div>
                    <div className="bar-under"></div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lang="5">
            <motion.h3 
            className="about-title pt-4"
            // variants={titleVariants} initial="hidden" animate="visible"
            >services</motion.h3>
          </Col>
          <Col lg="7">
            {services.map((data, i) => {
              return (
                <div className="service_ pt-4" key={i}>
                  <h5 className="service__title">{data.title}</h5>
                  <p className="service_desc">{data.description}</p>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
