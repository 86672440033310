import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRoutes from "./routes";
import Headermain from "../header";
import { Socialicons } from "../components/socialicons";
import { Navigation } from "../components/navigation";
import "./App.css";

export default function App() {
  const [loading, setLoading] = useState(true);
  const loader = document.getElementById("loader");
  if (loader) {
    setTimeout(() => {
      loader.style.height = "0";
      setLoading(false);
    }, 3000);
  }
  return (
    !loading && (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="cursor__dot"></div>
        <Navigation />
        <Socialicons />

        <div className="container-fixed mt-3">
          <Headermain />
          <AppRoutes />
          {/* <ThreeSphere /> */}
        </div>
      </Router>
    )
  );
}
