import React from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const ProjectDescription = ({ project }) => {
  return (
    <AnimatePresence>
      <motion.div className="proj-desc">
        <motion.h3>{project.name}</motion.h3>
        <motion.p key={project.id}>{project.description}</motion.p>
        <div className="link-cont">
          <a href={project.link} target="_blank">
            Demo
          </a>
          <a href={project.code} target="_blank">
            Code
          </a>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ProjectDescription;
