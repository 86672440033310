import React, { useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";

// const toggleActive = () => {
//   const btn = querySelectorAll(".home-btn");
//   btn.forEach((btn) => {

//   }
// }

export const Home = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="home-container align-self-center ">
          <div className="intro">
            <h1>{introdata.title}</h1>
            <h2 className="mb-2">Web Developer</h2>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
