import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

export const Navigation = () => {
  const location = useLocation();
  const isCurrentPage = (path) => location.pathname === path;

  return (
    <div className="nav-cont">
      <ul>
        <li className={isCurrentPage("/portfolio") ? "circle" : ""}>
          <Link className="home-btn" to="/portfolio">
            Portfolio
          </Link>
        </li>
        <li className={isCurrentPage("/about") ? "circle" : ""}>
          <Link className="home-btn" to="/about">
            Info
          </Link>
        </li>
        <li className={isCurrentPage("/contact") ? "circle" : ""}>
          <Link className="home-btn" to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};
