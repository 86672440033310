import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/home";
import { Portfolio } from "../pages/portfolio";
import { ContactUs } from "../pages/contact";
import { About } from "../pages/about";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

/* eslint-disable no-restricted-globals */

const blackBox = {
  initial: {
    height: "100vh",
  },
  animate: {
    height: 0,
  },
};

const InitialTransition = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <motion.div
        className="relative z-50 w-full bg-black"
        initial="initial"
        animate="animite"
        variants={blackBox}
      ></motion.div>
    </div>
  );
};

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatePresence>
        <Routes location={location}>
          <Route element={<InitialTransition />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default AppRoutes;
