import React from "react";
import { motion } from "framer-motion";

const opacity = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const ProjectList = ({ projects, handleClick, selectedProjectId }) => {
  return (
    <div className="proj-names">
      <motion.ul>
        {projects.map((project) => (
          <motion.li
            key={project.id}
            onClick={() => handleClick(project.id)}
            whileHover={{ skewX: "-2deg", opacity: 0.6, x: "1px" }}
            //whileTap={{ scale: 0.9 }}
            className={project.id === selectedProjectId ? "active" : ""}
            variants={opacity}
            initial="hidden"
            animate="visible"
          >
            {project.name}
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

export default ProjectList;
