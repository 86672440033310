import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { projects, meta } from "../../content_option";
import ProjectContainer from './ProjectContainer';
import { 
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue
} from 'framer-motion';

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

const hrVariants = {
  hidden: {
    width: '0px',
  },
  visible: {
    width: '100%',
    transition: {
      delay: 1,
      duration: 2,
      ease: 'easeInOut',
      type: 'spring',
    }
  },
}

const opacity = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
      ease: 'easeInOut',
    },
  },
};

export const Portfolio = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Projects | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="row-title mb-5 mt-3 pt-md-3">
          <Col lg="8" className="text-right">
            <motion.h1 
            className="display-6"
            variants={opacity} initial="hidden" animate="visible"
            > Projects</motion.h1>{" "}
            <motion.hr 
            className="t_border my-4 ml-0 text-left" variants={hrVariants} initial="hidden" animate="visible"/>
          </Col>
        </Row>
        <ProjectContainer/>
      </Container>
    </HelmetProvider>
  );
};
